import { all } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';

import login from '@spot-spotauth/modules/login/store';

import configs from './configs';

function* rootSaga() {
  yield all([
    login.saga.watch(),
    auth.saga.watch(),
    router.saga.watch(),
    configs.saga.watch(),
  ]);
}

export default rootSaga;
