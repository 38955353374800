import React from 'react';
import { useSelector } from 'react-redux';

import misc from '@spot-spotauth/store/configs/misc';

const LogoComponent = (props) => {
  const variantState = useSelector(misc.selector.selectVariant);

  const spotsat = process.env.PUBLIC_URL + '/img/logo-spotsat.png';
  // const spoteye = process.env.PUBLIC_URL + '/img/logo-spoteye.svg';
  const spoteye = process.env.PUBLIC_URL + '/img/logo-spotview-2024.png';
  const spotesg = process.env.PUBLIC_URL + '/img/logo-spotesg.png';
  const spotprospect = process.env.PUBLIC_URL + '/img/logo-spotprospect2.png';
  const spotview = process.env.PUBLIC_URL + '/img/logo-spotview.png';

  const variant = {
    spoteye,
    spotsat,
    spotesg,
    spotprospect,
    spotview,
  };

  return <img src={variant[variantState || '']} alt="logo" {...props} />;
};

export default LogoComponent;
