import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import LOGIN_ROUTES from './constants/loginRoutes.constant';

import useEffectOnce from '@spot-spotauth/hooks/useEffectOnce.hook';
import useQuery from '@spot/shared-hooks/useQuery.hook';

import misc from '@spot-spotauth/store/configs/misc';
import NewPassword from './screens/NewPassword/NewPassword.page';
import Login from './screens/Login/Login';
import ConfirmEmail from './screens/ConfirmEmail/ConfirmEmail.page';

const LoginModule = () => {
  const dispatch = useDispatch();

  const query = useQuery();

  const dispatchRedux = {
    updateState: flow(misc.action.updateState, dispatch),
  };

  useEffectOnce(() => {
    const redirectUrl =
      query.get('redirectUrl') || process.env.REACT_APP_SPOTEYE_URL;
    const variant = query.get('variant') || 'spoteye';

    dispatchRedux.updateState({
      redirectUrl,
      variant,
    });

    // history.replace(ROUTES.LOGIN.ROOT);
  });

  return (
    <>
      <Switch>
        <Route exact path={LOGIN_ROUTES.ROOT} component={Login} />
        <Route exact path={LOGIN_ROUTES.NEW_PASSWORD} component={NewPassword} />
        <Route
          exact
          path={LOGIN_ROUTES.CONFIRM_EMAIL}
          component={ConfirmEmail}
        />
      </Switch>
    </>
  );
};

export default LoginModule;
