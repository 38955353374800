import { all } from 'redux-saga/effects';

import dialogs from './dialogs';
import authenticate from './authenticate';
import account from './accounts';

function* watch() {
  yield all([
    dialogs.saga.watch(),
    authenticate.saga.watch(),
    account.saga.watch(),
  ]);
}

export default {
  watch,
};
