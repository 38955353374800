import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentPropsWithRef,
  useState,
} from 'react';
import styles from './PasswordInput.module.css';

import eyeClose from '../../assets/eyeClose.svg';
import eyeOpen from '../../assets/eyeOpen.svg';

type PasswordInputProps = {
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
  iconPassword?: boolean;
};

function PasswordInput(props: PasswordInputProps) {
  const { name, inputProps, onChange, iconPassword } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  function ShowPassword() {
    setShowPassword((prev) => !prev);
  }

  function onChangeWrapper(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    onChange && onChange(e);
  }

  return (
    <label htmlFor={name} className={styles.field}>
      {name}
      <div style={{ position: 'relative' }}>
        <input
          type={showPassword ? 'text' : 'password'}
          name={name}
          className={styles.input}
          onChange={onChangeWrapper}
          {...inputProps}
        />
        {iconPassword && (
          <div className={styles.divIcon} onClick={ShowPassword}>
            <img
              src={showPassword ? eyeOpen : eyeClose}
              alt="eye"
              className={styles.icon}
            />
          </div>
        )}
      </div>
    </label>
  );
}

export default PasswordInput;
