import { combineReducers } from 'redux';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';
import toaster from '@spot/shared-store/toaster';

import login from '@spot-spotauth/modules/login/store';

import configs from './configs';

const appReducer = combineReducers({
  [auth.name]: auth.reducer,
  [router.name]: router.reducer,
  [toaster.name]: toaster.reducer,
  [login.name]: login.reducer,
  [configs.name]: configs.reducer,
});

const rootReducer = (state, action) => {
  if (auth.constant.ACTION_TYPES.TOKEN.UNSET === action.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
