import React, { useEffect, useState } from 'react';
import styles from './ConfirmEmail.module.css';

import logo from '../../assets/logoSpotView.png';
import PasswordInput from '../../components/PasswordInput/PasswordInput.component';
import useWindowDimensions from '../../hooks/useWindowDimensions.hook';
import cls from '../../hooks/cls.util';
import Button from '../../components/Button/Button.component';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { flow } from 'lodash';
import useQuery from '@spot/shared-hooks/useQuery.hook';
import jwtDecode from 'jwt-decode';
import toaster from '@spot/shared-store/toaster';
import auth from '@spot/shared-store/auth';
import accounts from '../../store/accounts';

export default function ConfirmEmail(): JSX.Element {
  const query = useQuery();
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const [passwords, setPasswords] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>('');

  const token: any = query.get('token');
  const decodedToken: any = jwtDecode(token as any);
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const dispatchRedux = {
    unsetToken: flow(auth.action.unsetToken, dispatch),
    show: flow(toaster.action.show, dispatch),
    servicePatch: flow(accounts.action.servicePatch, dispatch),
  };

  function matchPassword(password: string) {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);
  }

  function updatePassword() {
    dispatchRedux.servicePatch({
      senha: passwords.password,
      token: token,
      account_id: decodedToken?.user.id,
      email_confirmed: true,
    });
  }

  useEffect(() => {
    if (!passwords.password && !passwords.confirmPassword) {
      setIsValid(false);
      return;
    }

    if (
      !matchPassword(passwords.password) ||
      !matchPassword(passwords.confirmPassword)
    ) {
      setError(
        'A senha deve conter pelo menos 8 caracteres, uma letra maiúscula e minúscula, um número e um caractere especial'
      );
      setIsValid(false);
      return;
    }

    if (passwords.password !== passwords.confirmPassword) {
      setError('As senhas devem ser iguais');
      setIsValid(false);
      return;
    }

    setError(undefined);
    setIsValid(true);
  }, [passwords.password, passwords.confirmPassword]);

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section className={styles.content}>
        <img className={styles.logo} alt="Logo" src={logo} />
        <form
          method="POST"
          onSubmit={(e) => e.preventDefault()}
          className={styles.form}
        >
          <div className={styles.heading}>
            <h1>Crie sua senha</h1>
            <p>
              Digite uma nova senha no formulário abaixo, e em seguida,
              utiliza-a para autenticar-se.
            </p>
          </div>
          <fieldset>
            <PasswordInput
              name="Senha"
              onChange={(e) =>
                setPasswords((prev) => ({ ...prev, password: e.target.value }))
              }
            />
            <PasswordInput
              name="Confirmar senha"
              onChange={(e) =>
                setPasswords((prev) => ({
                  ...prev,
                  confirmPassword: e.target.value,
                }))
              }
            />
            {error && <p className={styles.error}>{error}</p>}
          </fieldset>
          <div className={styles.actions}>
            <Button
              label="Escolher senha"
              onClick={updatePassword}
              buttonProps={{ disabled: !isValid }}
            />
          </div>
        </form>
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
          {!isTabletAndPhones && <span className={styles.preview}></span>}
        </section>
      )}
    </main>
  );
}
