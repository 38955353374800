export const NAME = 'misc';

const CONTEXT = 'CONFIG::MISC';

const RESET = `${CONTEXT}::RESET`;

const TOGGLE_MODE = `${CONTEXT}::TOGGLE::MODE`;

const UPDATE_MODE = `${CONTEXT}::UPDATE::MODE`;
const UPDATE_STATE = `${CONTEXT}::UPDATE::STATE`;

const TOGGLE = {
  MODE: TOGGLE_MODE,
};

const UPDATE = {
  MODE: UPDATE_MODE,
  STATE: UPDATE_STATE,
};

export const ACTION_TYPES = {
  UPDATE,
  RESET,
  TOGGLE,
};
