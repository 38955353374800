import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  email: string;
  host: string;
};

export default async function postApiAccountResetpasswordService(
  payload: Payload
) {
  const url = `accounts/reset-password`;
  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
