const ROUTES = {
  ROOT: '/login',
  NEW_PASSWORD: '/login/new-password',
  CONFIRM_EMAIL: '/login/confirm-email',
};

const LOGIN_ROUTES = {
  ROOT: ROUTES.ROOT,
  NEW_PASSWORD: ROUTES.NEW_PASSWORD,
  CONFIRM_EMAIL: ROUTES.CONFIRM_EMAIL,
};

export const NAMESPACE = ROUTES.ROOT;

export default LOGIN_ROUTES;
