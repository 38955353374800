import { ACTION_TYPES } from './accounts.constant';

export const serviceGet = () => ({
  type: ACTION_TYPES.SERVICE.GET,
});

export const serviceGetId = (payload: { account_id: string }) => ({
  type: ACTION_TYPES.SERVICE.GET_ID,
  payload,
});

export const servicePatch = (payload) => ({
  type: ACTION_TYPES.SERVICE.PATCH,
  payload,
});

export const serviceDelete = (payload: { account_id: string }) => ({
  type: ACTION_TYPES.SERVICE.DELETE,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET.FILTERS,
});
