import { combineReducers } from 'redux';

import dialogs from './dialogs';
import authenticate from './authenticate';
import account from './accounts';

const reducer = combineReducers({
  [authenticate.name]: authenticate.reducer,
  [dialogs.name]: dialogs.reducer,
  [account.name]: account.reducer,
});

export default reducer;
