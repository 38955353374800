import { combineReducers } from 'redux';

import dialogChangePassword from './dialogChangePassword';
import dialogChangeCompany from './dialogChangeCompany';

const reducer = combineReducers({
  [dialogChangePassword.name]: dialogChangePassword.reducer,
  [dialogChangeCompany.name]: dialogChangeCompany.reducer,
});

export default reducer;
