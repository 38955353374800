import reducer from './dialogChangeCompany.reducer';
import * as action from './dialogChangeCompany.action';
import * as selector from './dialogChangeCompany.selector';
import * as constant from './dialogChangeCompany.constant';
import initialState from './dialogChangeCompany.initialState';
import saga from './dialogChangeCompany.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
