import { flow } from 'lodash';

import { NAME } from './misc.constant';

import { selectState as selectStateConfigs } from '../configs.selector';

const selectStateKey = (state) => state[NAME];
const selectVariantKey = (state) => state.variant;
const selectRedirectToKey = (state) => state.redirectUrl;
const selectModeKey = (state) => state.mode;

export const selectState = flow(selectStateConfigs, selectStateKey);
export const selectMode = flow(selectState, selectModeKey);
export const selectVariant = flow(selectState, selectVariantKey);
export const selectRedirectTo = flow(selectState, selectRedirectToKey);
