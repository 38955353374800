import styles from './ForgotPasswordModal.module.css';
import iconClose from '../../../assets/close.svg';
import lock from '../../../assets/lock.png';
import { ChangeEvent, MouseEvent, useState } from 'react';
import Button from '../../Button/Button.component';
import TextInput from '../../TextInput/TextInput.component';
import { useDispatch } from 'react-redux';
import dialogChangeEmail from '../../../store/dialogs/dialogChangePassword';
import { flow } from 'lodash';

interface IProperties {
  forwardRef?: React.RefObject<HTMLDialogElement>;
  onSend?: (email: string) => void;
}

function ForgotPassword(props: IProperties): JSX.Element {
  const { forwardRef, onSend } = props;

  const [isValid, setIsValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const dispatch = useDispatch();

  const dispatchActions = {
    send: flow(dialogChangeEmail.action.servicePost, dispatch),
  };

  function onClose() {
    if (!forwardRef?.current) return;

    forwardRef.current.close();
  }

  function matchEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function onEmailChange(e: ChangeEvent<HTMLInputElement>) {
    const email: string = e.target.value;

    if (!matchEmail(email)) {
      setError('Email inválido');
      setIsValid(false);
      return;
    } else {
      setIsValid(true);
      setError('');
    }

    setEmail(email);
  }

  function onEmailSend(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatchActions.send({ email });

    if (!forwardRef?.current) return;

    forwardRef.current.close();
    onSend && onSend(email);
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={onClose}
          />
        </div>
        <div className={styles.divTitle}>
          <img src={lock} alt="lock" className={styles.iconLock} />
          <h3 className={styles.title}>Esqueceu a senha?</h3>
          <p className={styles.paragraph}>
            Não se preocupe, digite o <b>email cadastrado</b> e enviaremos um
            link para você recuperá-la!
          </p>
        </div>
        <TextInput name="Email" onChange={onEmailChange} />
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.divButton}>
          <Button
            label="Enviar"
            onClick={onEmailSend}
            buttonProps={{ disabled: !isValid }}
          />
        </div>
      </div>
    </dialog>
  );
}

export default ForgotPassword;
