import styles from './EmailSendModal.module.css';
import iconClose from '../../../assets/close.svg';
import email from '../../../assets/email.png';
import Button from '../../Button/Button.component';

interface IProperties {
  forwardRef?: React.RefObject<HTMLDialogElement>;
  value: string;
}

function EmailSendModal(properties: IProperties): JSX.Element {
  const { forwardRef, value } = properties;

  function onClose() {
    if (!forwardRef?.current) return;

    forwardRef.current.close();
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={onClose}
          />
        </div>
        <div className={styles.divTitle}>
          <img src={email} alt="lock" className={styles.iconLock} />
          <h3 className={styles.title}>Email enviado!</h3>
          <p className={styles.paragraph}>
            Enviamos um email para redefinir a sua senha para o email
            <br />
            <b>{value}</b>
          </p>
          <p className={styles.paragraph}>
            Verifique a sua caixa de entrada e spam.
          </p>
          <div className={styles.divButton}>
            <Button label="Fechar" onClick={onClose} />
          </div>
        </div>
      </div>
    </dialog>
  );
}

export default EmailSendModal;
