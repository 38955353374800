import LOGIN_ROUTES from '@spot-spotauth/modules/login/constants/loginRoutes.constant';

const NAMESPACE = '/';
const NAMESPACE_LOGIN = LOGIN_ROUTES.ROOT;

export const NAMESPACES = {
  ROOT: NAMESPACE,
  LOGIN: NAMESPACE_LOGIN,
};

const ROUTES = {
  ROOT: NAMESPACE,
  LOGIN: LOGIN_ROUTES,
};

export default ROUTES;
