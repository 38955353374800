import { all } from 'redux-saga/effects';

import dialogChangePassword from './dialogChangePassword';
import dialogChangeCompany from './dialogChangeCompany';

function* watch() {
  yield all([
    dialogChangePassword.saga.watch(),
    dialogChangeCompany.saga.watch(),
  ]);
}

export default {
  watch,
};
