import { isEqual } from 'lodash';
import flow from 'lodash/flow';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';
import auth from '@spot/shared-store/auth';
import misc from '@spot-spotauth/store/configs/misc';

import postApiAuthChangeCompanyService from '@spot/shared-services/account/postApiAuthChangeCompany.service';
import dialogChangeCompany from '.';

import currentStore from '.';

const keyCookie = {
  spotesg: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTPROSPECT,
  spotview: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTVIEW,
};

const domainCookie = {
  spotesg: process.env.REACT_APP_COOKIE_DOMAIN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_DOMAIN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_DOMAIN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_DOMAIN_SPOTPROSPECT,
  spotview: process.env.REACT_APP_COOKIE_DOMAIN_SPOTVIEW,
};

const redirectTo = {
  spotesg: process.env.REACT_APP_URL_SPOTESG,
  spotsat: process.env.REACT_APP_URL_SPOTSAT,
  spoteye: process.env.REACT_APP_URL_SPOTEYE,
  spotprospect: process.env.REACT_APP_URL_SPOTPROSPECT,
  spotview: process.env.REACT_APP_URL_SPOTVIEW,
};

function* handleReset() {
  const state = yield select(currentStore.selector.selectState);

  if (isEqual(currentStore.initialState, state)) {
    return true;
  }

  yield delay(500);

  yield put(currentStore.action.reset());
}

function* handleServicePost(action) {
  yield put(dialogChangeCompany.action.fetchStart());

  const variant = yield select(misc.selector.selectVariant);
  const redirectToState = yield select(misc.selector.selectRedirectTo);

  const handlers = {
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  const { company_id } = payload || {};

  try {
    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(postApiAuthChangeCompanyService, {
      company_id,
      token: payload.token,
    });

    if (!success) {
      yield put(
        toaster.action.show({ message: result?.message, variant: 'error' })
      );

      return yield put(dialogChangeCompany.action.fetchError(result));
    }

    const { token } = result;

    yield put(
      auth.action.setToken(token, keyCookie[variant], domainCookie[variant])
    );

    yield put(currentStore.action.fetchSuccess(payload));
    yield put(currentStore.action.updateVariant('finish'));

    yield call(() => {
      const URL = redirectTo[variant];

      const params = new URLSearchParams(window.location.search);

      window.location.href = `${URL}/login/auth?token=${token}&redirectUrl=${params.get(
        'redirectUrl'
      )}`;
    });
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield put(currentStore.action.fetchError(result));
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );

  yield takeLatest(currentStore.constant.ACTION_TYPES.SET.CLOSE, handleReset);
}

export default {
  watch,
};
