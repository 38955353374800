import { flow } from 'lodash';

import { NAME } from './dialogChangeCompany.constant';

import { selectState as selectStateDialog } from '../dialogs.selector';

const selectStateKey = (state) => state[NAME];
const selectVisibleKey = (state) => state.visible;
const selectLoadingKey = (state) => state.loading;
const selectPayloadKey = (state) => state.payload;
const selectVariantKey = (state) => state.variant;
const selectDataKey = (state) => state.data;

export const selectState = flow(selectStateDialog, selectStateKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectVisible = flow(selectState, selectVisibleKey);
export const selectVariant = flow(selectState, selectVariantKey);
export const selectPayload = flow(selectState, selectPayloadKey);
export const selectData = flow(selectState, selectDataKey);
