import { ACTION_TYPES } from './misc.constant';

export const updateMode = (payload: 'light' | 'dark') => ({
  type: ACTION_TYPES.UPDATE.MODE,
  payload,
});

export const updateState = (payload) => ({
  type: ACTION_TYPES.UPDATE.STATE,
  payload,
});
